html,
body,
#root {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
}
* {
  box-sizing: border-box;
}
